require("./packs/feedback")
require("./packs/menu")

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function() {
    /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
    this.classList.toggle("active");

    /* Toggle between hiding and showing the active panel */
    var panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  });
}

var slider = tns({
  autoplay: true,
  arrowKeys: true,
  container: '.slider',
  controlsContainer: '.slider__controls',
  controlsPosition: 'bottom',
  autoplayButtonOutput: false,
  items: 1,
  loop: true,
  navPosition: 'bottom',
  responsive: {
    768: {
      items: 2,
      slideBy: 2
    },
    992: {
      items: 3,
      slideBy: 1
    },
    1200: {
      items: 4,
      slideBy: 1
    }
  }
});

var sliderTest = tns({
  autoplay: true,
  arrowKeys: true,
  container: ".slider--test",
  controlsContainer: ".slider--test__controls",
  controlsPosition: "bottom",
  autoplayButtonOutput: false,
  items: 1,
  loop: true,
  navPosition: "bottom",
  responsive: {
    768: {
      items: 1,
      slideBy: 1,
    },
    992: {
      items: 2,
      slideBy: 1,
    },
    1200: {
      items: 3,
      slideBy: 1,
    },
  },
});

var sliderHero = tns({
  autoplay: true,
  arrowKeys: true,
  container: ".slider--hero",
  controlsContainer: ".slider--hero__controls",
  controlsPosition: "bottom",
  autoplayButtonOutput: false,
  items: 1,
  loop: true,
  navPosition: "bottom",
});

// hide and show contacts after choosing feedback dropdown, used in shared/_form.html.erb

var showContacts = document.querySelector("#show-contacts");
var hiddenContacts = document.getElementById("hidden-cotacts");

showContacts.addEventListener("change", function() {
  // the hardcoded text "I'm looking for more info...." needs to be MATCHED the text in shared/_form.html.erb
  if (showContacts.value == "I'm looking for more information on The Pool") {
    hiddenContacts.style.display = "block";
  } else {
    hiddenContacts.style.display = "none";
  }
});
